import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './Style/Model.css'
import './scss/style.scss'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const DefaultLayoutAdmin = React.lazy(() => import('./layout/DefaultLayoutAdmin'))
const ArVr = React.lazy(() => import('./testOnly/arVr.js'))

// Pages
// const Login = React.lazy(() => import('./views/Login'))

class App extends Component {
    render() {
        return (
            // <Router basename="/shopsze">
            <>
                <Router>
                    <Suspense fallback={loading}>
                        <Routes>
                            <Route path="/administrator/*" name="Home" element={<DefaultLayoutAdmin />} />
                            <Route path="/arVr" name="arVr" element={<ArVr />} />
                            <Route path="*" name="Home" element={<DefaultLayoutAdmin />} />
                        </Routes>
                    </Suspense>
                </Router>
            </>
        )
    }
}

export default App
