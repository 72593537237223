import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { headerAPI,  softcornerAlluser, listOfAllUsers, adsCampaigns} from '../../../config'

export const AdsContext = createContext()

export const AdsProvider = ({ children }) => {
    const headers = headerAPI()
    const [adsData, setAdsData] = useState([])
    const [userDataList, setUserList] = useState([])
    const [totalPages, setTotalpages] = useState('')
    const [totalPages1, setTotalpages1] = useState('')
    const [msg, setMsgs] = useState(null)
    const [trigger, setTrigger] = useState(true)
   

    const [Data, setData] = useState([])
    //ads api .......
    const postData = (reqData) => {
        console.log('reqData', reqData)

        axios
            .post(adsCampaigns, reqData, { headers: headers })
            .then((response) => {
                setAdsData(response.data.data)
                console.log('data1', response.data.data)
                setTotalpages(response.data.totalPages)
                setMsgs(null)
            })
            .catch((error) => {
                console.error('Error posting data', error.response?.data?.status || error.message)
                setMsgs(error.response?.data?.status)
            })
    }
    //end  ads api ......

    //user list api .......
    const UserList = (filter) => {
        axios
            .post(listOfAllUsers, filter, { headers: headers })
            .then((reponse) => {
                console.log('data total', reponse.data.total)

                setUserList(reponse.data.data)
                setTotalpages1(reponse.data.totalPages)
            })
            .catch((error) => {
                console.error('Error posting data', error.response?.data?.status || error.message)
            })
    }
    //user  Soft corner....

    const softcorner = (filter) => {
        axios
            .post(softcornerAlluser, filter, { headers: headers })
            .then((response) => {
                console.log('data total', response.data.data)
                setData(response.data.data)
            })
            .catch((error) => {
                console.error('Error posting data', error.response?.data?.status || error.message)
            })
    }

    useEffect(() => {
        postData()
        UserList()
        softcorner()
    }, [])

    return <AdsContext.Provider value={{ adsData, postData, UserList, totalPages, msg, totalPages1, userDataList, setTrigger, trigger, softcorner, Data }}>{children}</AdsContext.Provider>
}

AdsProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
